//
// Get Widget
//

.gw-btn-icon {
	font-size: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

.gw-btn-m {
	height: 50px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #ffffff;
	background: linear-gradient(90deg, #aa7eda -18.35%, #4c67f6 197.98%);
	border-radius: 12px;
	width: 250px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	padding: 10px 16px;
}

.gw-btn-s {
	height: 50px;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	color: #ffffff;
	background: linear-gradient(90deg, #80da7e -18.35%, #4c9af6 197.98%);
	border-radius: 12px;
	width: 250px;
	padding: 16px;
}

.gw-title {
	font-style: normal;
	font-weight: 600;
	font-size: 32px;
	line-height: 46px;
	color: #121624;
}

.gw-navigation-link {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 23px;
	color: #ffffff;
}

.gw-bg-grey {
	background-color: #f5f6fa !important;
}

.container.gw-container {
	padding-left: 15px;
	padding-right: 15px;
}

.form-control {
	box-shadow: none !important;
}

.gw-form-control {
	height: 60px;
	width: 100%;
}

.gw-input {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #1c2134;
	border-radius: 0 !important;
	border: 0;
	border-bottom: 1px #b4becf solid;
	padding-left: 0 !important;
	padding-right: 0 !important;
	background-image: none !important;
	background-color: white;
}

.gw-input.form-control:focus {
	background-color: white;
	border-color: #4c67f6;
}

.gw-input::placeholder {
	color: #b4becf;
}

.gw-widget-title {
	font-family: 'Jost', sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	color: #121624;
}

.gw-sub-title {
	font-family: 'Jost', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 29px;
	text-align: center;
	color: #707496;
}

.gw-main-button {
	width: 238px;
	height: 60px;
	background: #aa7eda;
	box-shadow: 0px 10px 10px rgba(202, 157, 250, 0.5);
	border-radius: 12px;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 29px;
	text-align: center;
	color: #ffffff;
	border: 0;

	&:hover {
		background: #a070d0;
		box-shadow: 0px 10px 10px rgba(202, 157, 250, 1);
		border: 0;
		color: #ffffff;
	}

	&:active {
		background: #aa7eda;
		color: #ffffff;
	}

	&:focus {
		background: #aa7eda;
		color: #ffffff;
	}
}

.gw-link-text {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #707496;
}
.gw-btn-link {
	padding: 0;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #707496;
	border-bottom: 1px solid #707496 !important;
	border-radius: 0;
	padding-bottom: 2px;
	margin-left: 5px;

	&:hover {
		color: #4c67f6;
		border-bottom: 1px solid #4c67f6 !important;
	}
}

.gw-app-card {
	position: relative;
	overflow: hidden;
}

.gw-app-card:hover .gw-app-card-btn {
	opacity: 1;
}

.gw-app-card-btn {
	opacity: 0;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	cursor: pointer;
	z-index: 5;
	background-color: rgba(255, 255, 255, 0.9);
}

.background-flip {
	-moz-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	border-radius: 0 20px 20px 0;
}

.gw-link {
	text-decoration: none;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 22px;
	color: #4c67f6;
}

.row.gw-row {
	--bs-gutter-x: 1rem;
	margin-right: calc(-1 * var(--bs-gutter-x));
	margin-left: calc(-1 * var(--bs-gutter-x));
}

.col-3.gw-col {
	padding-right: calc(var(--bs-gutter-x) * 1);
	padding-left: calc(var(--bs-gutter-x) * 1);
}

.gw-lg-btn {
	width: 250px;
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.gw-h1-title {
	font-family: 'Jost';
	font-size: 32px;
	font-weight: 600;
	text-align: center;
}

.gw-h2-title {
	font-family: 'Jost';
	font-size: 20px;
	font-weight: 400;
	text-align: center;
}

.gw-pink-btn {
	background: linear-gradient(90deg, #fb5c7d -18.35%, #8849d9 197.98%);
	border-radius: 12px;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	color: #ffffff;
	border: 0;
}

.gw-purple-btn {
	background: linear-gradient(90deg, #aa7eda -18.35%, #4c67f6 197.98%);
	border-radius: 12px;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	color: #ffffff;
	border: 0;

	&:hover {
		color: #ffffff !important;
	}
}

.gw-green-btn {
	background: linear-gradient(90deg, #80da7e -18.35%, #4c9af6 197.98%);
	border-radius: 12px;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	color: #ffffff;
	border: 0;
}

//temp override values
.app {
	background-color: #f5f6fa !important;
	min-width: 1120px;

	& .aside ~ .wrapper {
		padding-left: 14rem !important;
	}

	& .header {
		margin: 1rem 1rem;
		margin-bottom: 1.5rem;
		padding: 0 1rem !important;
		border-radius: 10px;

		& .container-fluid {
			padding: 0 1rem;
		}
	}

	.mobile-block {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		font-size: 20px;
		font-weight: 600;
		line-height: 29px;
		padding: 30vmin;
		text-align: center;
	}

	//@media (max-width: 1200px) {
	//	.wrapper {
	//		width: 1160px;
	//		min-width: 1160px;
	//	}
	//}

	//@media (max-width: 1170px) {
		// .wrapper {
		// 	display: none;
		// }
		// .aside {
		// 	display: none;
		// }

		// .mobile-block {
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// }
	//}
}

.col-3.gw-col .card-header + .card-body {
	padding-top: 0;
}

.col-3.gw-col .card {
	border-radius: 14px;
}

.col-3.gw-col .gw-widget-title {
	height: 64px;
	align-items: center;
	display: flex;
	align-items: center;
}

.gw-description {
	height: 60px;
	max-height: 60px;
	overflow: hidden;
	margin-bottom: 28px;
}

.gw-tab-container {
	display: flex;
	justify-content: space-between;
	min-width: 400px;
}

.gw-tab {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 32px;
	color: #121624;
	padding: 0;
	border: 0;

	&.active {
		font-weight: 500;
		color: #aa7eda;
	}
}

// some
.card-container {
	display: flex;
}

.gw-plan-title {
	font-weight: 600;
	font-size: 32px;
	line-height: 46px;
	text-align: center;
	color: #121624;
}

.gw-plan-sub-title {
	font-weight: 400;
	font-size: 20px;
	line-height: 29px;
	color: #707496;
}

.gw-plan-select-btn {
	width: 91px;
	height: 40px;
	border: 1px solid #aa7eda;
	border-radius: 12px;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;
	text-align: center;
	color: #121624;
	padding: 8px;
}

.gw-plan-select-btn.active {
	background: #9f7cde;
	color: #ffffff;
}

.gw-plan-card {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.gw-plan-card_name {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 32px;
	text-align: center;
	text-transform: uppercase;
	color: #121624;
	margin-bottom: 5px;
}

.gw-plan-card_description {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #707496;
	min-height: 32px;
}

.gw-plan-card_price {
	font-family: 'Jost';
	font-style: normal;
	font-weight: 500;
	font-size: 48px;
	line-height: 69px;
	text-align: center;
	color: #121624;
	position: relative;
	display: flex;
	justify-content: center;
	width: max-content;
	margin: auto;
	margin-bottom: 16px;
}

.gw-plan-card_price-currency {
	position: absolute;
	right: -20px;
	top: 12px;
	font-weight: 500;
	font-size: 20px;
	line-height: 23px;
	color: #121624;
}

.gw-plan-card_price-discount {
	position: absolute;
	right: -20px;
	bottom: 8px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	color: #ffffff;
	width: 36px;
	height: 20px;
	background: linear-gradient(185.04deg, #fb5c7d -19.36%, #8849d9 196.25%);
	border-radius: 5px;
}

.gw-plan-card_price-base {
	position: absolute;
	bottom: -4px;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	text-decoration-line: line-through;
	color: #afb1c2;
}

.gw-plan-option-row {
	border-top: 1px solid #e9ecf1;
	height: 38px;
	padding: 10px 0;
	font-family: 'Jost';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #707496;
}

.gw-plan-option-row.exclusive {
	color: #4c67f6;
}

.gw-plan-submit {
	height: 50px;
	padding: 10px;
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	color: #ffffff;
	background: linear-gradient(90deg, #aa7eda -18.35%, #4c67f6 197.98%);
	border-radius: 12px;
	position: relative;

	&.gw-plan-submit--free {
		background: #e2e8ff;
		color: #4c67f6;
	}
}

.gw-plan-submit svg {
	position: absolute;
	right: 10px;
	top: 10px;
}

// common analytics

.gw-analytics__header {
}

.gw-analytics__header-label {
	display: flex;
	margin-bottom: 70px;
	justify-content: space-between;
}

.gw-analytics-title {
	font-weight: 600;
	font-size: 32px;
	line-height: 46px;
	color: #121624;
}

.gw-analytics-subtitle {
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	text-align: center;
	color: #707496;
	max-width: 520px;
}

.gw-analytics-controls {
	display: flex;
	justify-content: flex-end;
}

.gw-analytics-table_row {
	border-bottom: 2px solid #e9ecf1;
	padding: 1rem 0;
	align-items: center;
	& > div {
		text-align: center;
	}
}

.gw-analytics-common-link {
	cursor: pointer;
	color: #aa7eda;
	text-decoration: underline;
}

.gw-analytics-btn {
	font-weight: 500;
	font-size: 16px;
	line-height: 23px;
	color: #ffffff;
	width: 180px;
	height: 50px;
	background: linear-gradient(90deg, #80da7e -18.35%, #4c9af6 197.98%);
	border-radius: 12px;
	border: none;
	outline: none;
	cursor: pointer;
}

// widget analytics

#portal-notification .rnc__notification-item--danger {
	background-color: #dc3545;
}
